@use "../../abstracts/" as *;
@use "../../abstracts/media" as *;
.relative-selector {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px 15px;
  width: 100%;
  @include media(">=ipadh") {
    grid-template-columns: 1fr 1fr;
  }
  .count {
    position: absolute;
    top: -12px;
    right: -1px;
    padding: 3px 9px;
    border-radius: 30px;
    @include paragraph-fs(small);
    @include color(background-color, accent-teal-dark);
    @include color(color, white);
  }
}
