@use "../../abstracts/" as *;
.language-change-confirmation {
  position: absolute;
  top: 2%;
  left: 2%;
  width: 96%;
  height: 95%;
  padding: 30px;
  @include color(background-color, gray-light);
  &.closed {
    display: none;
  }
  h5 {
    margin-top: 0;
  }
}
