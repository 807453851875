@use "../../abstracts/" as *;
.listening-example {
  display: flex;
  > * + * {
    margin-left: 20px;
  }
  .col {
    width: 50%;
  }
  .title {
    text-transform: uppercase;
    @include color(color, secondary-dark);
    @include paragraph-fs(small);
  }
}
