@use "../base/" as *;

.flex {
  display: flex;
  &-center {
    justify-content: center;
    align-items: center;
  }
  &-column {
    flex-direction: column;
  }
}
.full-width {
  width: 100%;
}
.txt-center {
  text-align: center;
}
.details {
  @extend .p-small;
  margin-top: 57px;
}
