@use "./fonts" as *;

$flow-space: 1.5rem;
$flow-space-med: 1.25rem;
$flow-space-small: 1.125rem;
$flow-space-large: 3rem;
$paragraph-spacing: 1.5rem;
$list-spacing: 0.75rem;

$page-padding-mobile: 24px;
$page-padding-tablet: 32px;
$page-padding-desktop: 64px;

$header-mobile: 64px;
$header-tablet: 95px;
$header-desktop: 170px;

$column-tablet: 496px;
$column-desktop: 550px;
