@use "../../abstracts/media" as *;
.alert {
  width: 100%;
  max-width: 390px;
  padding: 20px 40px;
  margin: 10px auto 30px;
  text-align: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  @include media(">=ipadh") {
    max-width: 498px;
  }
  @include media(">=hidpi") {
    max-width: 552px;
  }
}
