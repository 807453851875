@use "../abstracts/media" as *;
.page-intervention {
  display: flex;
  justify-content: center;
  .content {
    width: 100%;
    max-width: 390px;
    margin-block-end: 50px;
    padding: 0 20px;
    @include media(">=ipadh") {
      max-width: 498px;
      margin-block-end: 80px;
      padding: 0;
    }
    @include media(">=hidpi") {
      max-width: 552px;
    }
  }
}
