#modal-contact {
  height: min(95%, 700px);
  .modal-content {
    height: auto !important;
    .page-intervention .content {
      margin: 0 !important;
    }
  }
}
.contact-content {
  width: 100%;
  // max-width: 235px;
  margin: 0 auto;
}
