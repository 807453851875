@use "../abstracts/" as *;
@use "../abstracts/media" as *;
body {
  font-family: $ff-roboto;
  @include paragraph-fs(regular);
  @include color(color, text);
}
h1,
.h1 {
  font-family: $ff-bilo;
  font-weight: $fw-m;
  @include heading-fs(h1);
  @include color(color, primary);
  margin-bottom: 0.5em;
  @include media(">=800px") {
    @include heading-fs(h1-plus);
  }
}
h2,
.h2 {
  font-family: $ff-bilo;
  font-weight: $fw-m;
  @include color(color, primary);
  @include heading-fs(h2);
  margin-bottom: 0.5rem;
  @include media(">=800px") {
    @include heading-fs(h2-plus);
  }
}
h3,
.h3 {
  font-family: $ff-bilo;
  font-weight: $fw-m;
  @include heading-fs(h3);
  @include color(color, text);
  margin-bottom: 0.3em;
  @include media(">=800px") {
    @include heading-fs(h3-plus);
  }
}
h4,
.h4 {
  font-family: $ff-bilo;
  font-weight: $fw-m;
  @include heading-fs(h4);
  @include color(color, text);
  margin-bottom: 0.3em;
}
h5,
.h5 {
  font-family: $ff-bilo;
  font-weight: $fw-m;
  @include heading-fs(h5);
  @include color(color, primary);
  margin-bottom: 0.3em;
}
a,
a:visited {
  @include color(color, text);
  &:hover {
    @include color(color, primary-med);
  }
}
p {
  margin-bottom: $paragraph-spacing;
}
.p-small {
  @include paragraph-fs(small);
  font-weight: $fw-r;
  @include color(color, gray-dark);
}
ul,
ol {
  padding-left: 27px;
  margin: 0 0 $paragraph-spacing 0;
  li {
    margin: 0 0 $list-spacing 0;
    &:last-child {
      margin: 0;
    }
  }
}
textarea {
  width: 100%;
  padding: 20px;
}
.quote,
blockquote {
  margin-bottom: $paragraph-spacing;
  font-style: italic;
  font-family: $ff-sspro;
  font-weight: $fw-m;
  @include serif-fs(blockquote);
  &::before {
    content: "“";
  }
  &::after {
    content: "”";
  }
}
