@use "../../abstracts/" as *;
dialog:not([open]) {
  display: none;
}
.strapi-modal,
.menu-modal {
  padding: 0;
  border-radius: 16px;
  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  width: min(95%, $column-tablet + 40px);
  max-width: 95%;
  height: min(95%, 900px);
  max-height: 100%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  user-select: text;
  visibility: visible;
  overflow: auto;
  &::backdrop {
    background: rgba(0, 0, 0, 0.75);
  }
  .modal-close {
    width: 35px;
    height: 0;
    padding-top: 35px;
    overflow: hidden;
    border: none;
    background: url("../../../images/close.svg") no-repeat center transparent;
    background-size: contain;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      @include color(background-color, gray-light);
    }
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 0 44px 24px;
    header {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      padding: 0 24px 0 0;
      margin-bottom: 15px;
    }
    section {
      flex-grow: 1;
      overflow-y: scroll;
      padding-right: 20px;
      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
  &.fit {
    // height: fit-content;
    .modal-content {
      padding: 24px 24px 46px;
      header {
        padding: 0;
      }
      section {
        padding-right: 0;
      }
    }
  }
}

.modal-email-preview {
  .preview {
    display: flex;
    flex-direction: column;
  }
  iframe {
    flex-grow: 1;
    border: 1px solid;
    @include color(border-color, primary);
  }
}
