@use "../../abstracts/" as *;
@use "../../abstracts/media" as *;

.language-switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  @include media(">=ipadh") {
    flex-direction: row;
  }
  &.menu {
    @include color(background-color, primary);
  }
}

.switch,
.language-switch {
  display: inline-flex;
  padding: 2px;
  margin-bottom: 10px;
  border-radius: 12px;
  border: 1px solid;
  @include color(border-color, primary);
  @include media(">=ipadh") {
    margin-bottom: 0;
  }
  &.menu {
    margin: 10px 0;
    border: none;
  }
}

.switch,
.language-switch {
  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
  }
}

.switch,
.language-switch {
  label {
    padding: 8px 15px;
    margin: 0;
    text-align: center;
    @include paragraph-fs(small);
    @include color(color, primary);
  }
}

.language-switch.menu label {
  @include color(color, white);
}

.language-switch.menu label {
  padding: 5px 8px;
  @include paragraph-fs(small);
}

.switch,
.language-switch {
  label:hover {
    cursor: pointer;
  }
}

.switch,
.language-switch {
  input:checked + label {
    @include color(background-color, primary);
    @include color(color, white);
    border-radius: 10px;
  }
}

.language-switch.menu input:checked + label {
  @include color(background-color, white);
  @include color(color, primary);
  border-radius: 10px;
}
