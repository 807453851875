@use "../abstracts/" as *;

.form-label {
  display: block;
  width: 100%;
  margin-bottom: 6px;
  font-weight: $fw-b;
  @include form-fs(label);
}
.form-input {
  display: block;
  width: 100%;
  padding: 10px 16px;
  @include form-fs(field);
  border: 1px solid;
  @include color(border-color, gray-med-light);
  border-radius: 2px;
}

form,
.form {
  label {
    @extend .form-label;
  }
  input {
    @extend .form-input;
  }
  //Checkbox
  input[type="checkbox"] {
    appearance: none;
    display: inline-grid;
    place-content: center;
    width: 1.15em;
    height: 1.15em;
    margin: 0 5px 0 0;
    padding: 0;
    @include color(background-color, white);
    @include color(color, gray-med-light);
    border: 1px solid;
    @include color(border-color, gray-med-light);
    border-radius: 2px;
    transform: translateY(-0.075em);
  }
  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary);
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  //Radio
  input[type="radio"] {
    flex-shrink: 0;
    appearance: none;
    @include color(background-color, white);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.35em;
    height: 1.35em;
    margin: 0 1em 0 0;
    padding: 0;
    border: 1px solid;
    @include color(border-color, gray-med-light);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: inline-grid;
    place-content: center;
  }
  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary);
  }
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  input[type="radio"].checked::before {
    transform: scale(1);
  }

  //Select
  select {
    display: inline-block;
    width: 100%;
    padding: 15px;
    background: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 25px) calc(1em + 5px),
      calc(100% - 20px) calc(1em + 5px), calc(100% - 2.5em) 0.6em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    &::focus {
      background-image: linear-gradient(45deg, green 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, green 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.6em;
      background-size: 5px 5px, 5px 5px, 1px 1.5em;
      background-repeat: no-repeat;
      border-color: green;
      outline: 0;
    }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }

  //Text Area
  textarea {
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  textarea.noresize {
    resize: none;
  }

  .error {
    @include paragraph-fs(small);
    @include color(color, error);
  }

  a,
  a:visited {
    text-decoration: underline;
  }
}
.form-radio {
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 1rem;
  label {
    font-weight: $fw-r;
  }
}
