@use "../abstracts/" as *;
@use "../abstracts/media" as *;
@use "../utilities/buttons" as *;
@use "../utilities/lists" as *;
.home-row {
  position: relative;
  &-content {
    margin-inline: auto;
    width: min(84%, 865px);
    @include media(">=hidpi") {
      width: min(84%, 936px);
    }
  }
  &-1 {
    height: auto;
    margin-top: 13px;
    @include media(">768px") {
    }
    @include media(">=ipadh") {
      height: 67vw;
    }
    @include media(">=mdpi") {
      height: 57vw;
    }
    @include media(">=hidpi") {
      height: 50vw;
    }
    @include media(">=1600px") {
      height: 48vw;
    }
    @include media(">=desktop") {
    }
    .home-row-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: min(84%, 720px);
      z-index: 3;
      height: 100%;
      .intro-video {
        width: 100%;
        .center {
          max-width: 100%;
          margin: 0 auto 2rem;
          @include media(">=ipadh") {
            max-width: 40%;
          }
          @include media(">=mdpi") {
            max-width: 60%;
          }
          @include media(">=desktop") {
            max-width: 70%;
          }
          @include media(">1980px") {
            max-width: 90%;
          }
        }
      }
      .alt-action {
        width: 100%;
        margin-bottom: 4rem;
        text-align: center;
        @include media(">=ipadh") {
          margin-bottom: 0;
        }
      }
      .scroll-action {
        display: none;
        position: absolute;
        bottom: 60px;
        left: calc(50% - 21.5px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          .scroll-action-icon {
            &::before {
              background: var(--primary-light);
            }
            svg {
              color: white;
            }
          }
        }
      }
      .scroll-action-icon {
        position: relative;
        width: 32px;
        height: 32px;
        margin-top: 0.5rem;
        &::before {
          content: "";
          position: absolute;
          top: -3px;
          left: -3px;
          width: 38px;
          height: 38px;
          background: #a6d2f2;
          border-radius: 50%;
        }
        svg {
          position: relative;
          color: #595857;
        }
      }
    }
    &-bottom {
      display: none;
      @include media(">=ipadh") {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        @include color(background-color, accent-desat-teal-lt);
      }
    }
    h1 {
      margin: 0 auto 2.5rem;
      text-align: center;
      font-weight: $fw-m;
      span {
        display: block;
      }
      @include heading-fs(hero-sub-plus);

      @include media(">=ipadh") {
        max-width: 740px;
        margin: -8rem auto 2.5rem;
        text-align: center;
        //@include heading-fs(hero-plus);
      }
      @include media(">=1200px") {
        //@include heading-fs(hero-mega);
      }
      @include media(">=desktop") {
        @include heading-fs(hero-plus);
      }
      @include media(">1980px") {
        margin-bottom: 4rem;
      }
    }
    h2 {
      margin-bottom: 2.5rem;
      padding: 0;
      text-align: center;
      font-weight: $fw-r;
      font-family: $ff-roboto;
      font-style: normal;
      @include heading-fs(hero-sub-mini);
      @include color(color, primary);

      @include media(">=ipadh") {
        max-width: 450px;
      }
      @include media(">=hidpi") {
        //max-width: 740px;
      }
      @include media(">=desktop") {
      }
      @include media(">1980px") {
        margin-bottom: 4rem;
      }
    }
    button {
      @extend .button;
      padding: 15px 30px;
      margin-bottom: 1.25rem;
      @include color(background-color, accent-teal);
      @include color(border-color, accent-teal-dark);
      box-shadow: 0px 4px 24px rgba(0, 168, 180, 0.45);
      border-radius: 10px;
      text-transform: uppercase;
      &:hover {
        @include color(background-color, primary);
        @include color(color, white);
      }
    }
  }
  &-2 {
    //What it is
    @include color(background-color, accent-desat-teal-lt);
    .home-row-content {
      padding: 55px 0;
      @include media(">=mdpi") {
        padding: 15px 0 55px;
      }
    }
    h2 {
      max-width: 710px;
      margin: 0 auto 27px;
      @include front-fs(h2);
      @include color(color, primary);
      text-align: center;
    }
    h3 {
      max-width: 520px;
      margin: 0 auto 33px;
      @include color(color, primary);
      @include serif-fs(intro);
      text-align: center;
    }
    ul {
      list-style-type: none;
      @extend .fancy-checks;
      max-width: 670px;
      margin: 0 auto;
      li {
        margin-bottom: 1.2rem;
      }
    }
    p {
      max-width: 610px;
      margin: 0 auto 1.2em;
    }
  }
  &-3 {
    //How it works
    padding: 64px 32px;
    @include color(background-color, white);
    .home-row-content {
      width: min(84%, 1300px);
    }
    .home-row-title {
      margin-bottom: 54px;
      @include front-fs(h2);
      @include color(color, primary);
      text-align: center;
    }
    .steps {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 54px;
        @include media(">=ipadh") {
          margin-top: 0;
          margin-left: 44px;
        }
      }
      @include media(">=ipadh") {
        flex-direction: row;
        justify-content: center;
      }
    }
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      & > * + * {
        margin-top: 25px;
        @include media(">=ipadv", "<ipadh") {
          margin-top: 0;
          margin-left: 25px;
        }
      }
      @include media(">=ipadv") {
        flex-direction: row;
      }
      @include media(">=ipadh") {
        flex-direction: column;
        max-width: 363px;
      }
      .title {
        flex-shrink: 0;
        @include media(">=ipadv", "<ipadh") {
          max-width: 60px;
        }
        h3 {
          margin: 0;
          line-height: 64px;
          font-weight: $fw-r;
          @include front-fs(h2-sub);
          @include color(color, primary);
          @include media(">=ipadv", "<ipadh") {
            margin-top: -20px;
            line-height: 58px;
          }
          span {
            @include media(">=ipadv", "<ipadh") {
              @include heading-fs(h5);
            }
          }
        }
      }
      .image {
        flex-shrink: 0;
        img {
          width: 150px;
          height: auto;
        }
      }
      .markup {
        text-align: center;
      }
    }
    .arrow {
      display: none;
      flex-shrink: 0;
      width: 86px;
      height: 35px;
      margin-top: 160px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @include media(">=mdpi") {
        display: block;
      }
      &-1 {
        background-image: url(../../images/arrows/arrow_1.png);
      }
      &-2 {
        background-image: url(../../images/arrows/arrow_2.png);
      }
    }
    .explanation {
      margin: 46px auto 0;
      max-width: 924px;
      text-align: center;
      font-family: $ff-bilo;
      @include media(">=ipadv") {
        @include heading-fs(hero-sub);
      }
    }
  }
  &-4 {
    //Why it's important
    padding: 64px 48px;
    @include color(background-color, accent-desat-teal-lt);
    @include media(">=ipadh") {
      padding: 64px 48px 84px;
    }
    .home-row-title {
      margin-bottom: 54px;
      @include front-fs(h2);
      @include color(color, primary);
      text-align: center;
    }
    .testimonials {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 54px;
        @include media(">=ipadh") {
          margin-top: 0;
          margin-left: 54px;
        }
      }
      @include media(">=ipadh") {
        flex-direction: row;
        justify-content: center;
      }
    }
    .testimonial {
      display: flex;
      flex-direction: column;
      width: 100%;
      & > * + * {
        margin-top: 16px;
      }
      @include media(">=ipadv") {
        flex-direction: row;
      }
      @include media(">=ipadh") {
        flex-direction: column;
        width: 33.3333%;
        max-width: 404px;
      }
      &:nth-child(even) {
        @include media(">=ipadv", "<ipadh") {
          flex-direction: row-reverse;
        }
        .video {
          @include media(">=ipadv", "<ipadh") {
            margin-left: 20px;
            margin-right: 0;
          }
        }
      }
      .video {
        width: 100%;
        flex-shrink: 0;
        @include media(">=ipadv") {
          width: 55%;
        }
        @include media(">=ipadv", "<ipadh") {
          margin-right: 20px;
          margin-left: 0;
        }
        @include media(">=ipadh") {
          width: 100%;
        }
      }
      .text {
        & > * + * {
          margin-top: 16px;
        }
      }
      .quote {
        margin-top: 24px;
        @include serif-fs(intro);
        font-family: $ff-sspro;
        font-weight: $fw-sb;
        font-style: italic;
      }
      .description {
        @include paragraph-fs(small);
      }
    }
  }
  &-5 {
    //Who we are
    padding: 64px 48px;
    .home-row-title {
      margin-bottom: 20px;
      @include front-fs(h2);
      @include color(color, primary);
      text-align: center;
    }
    .study-team-members {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 64px;
        @include media(">=ipadh") {
          margin-top: 0;
          margin-left: 100px;
        }
        @include media(">=hidpi") {
          margin-left: 100px;
        }
      }
      @include media(">=ipadh") {
        flex-direction: row;
        justify-content: center;
      }
    }
    .member {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include media(">=ipadv") {
        flex-direction: row;
      }
      @include media(">=ipadh") {
        flex-direction: column;
        max-width: 280px;
      }
      .info {
        margin-top: 16px;
        @include media(">=ipadv") {
          margin-top: 0;
          padding-left: 32px;
        }
        @include media(">=ipadh") {
          margin-top: 16px;
          padding-left: 0;
        }
      }
      .picture {
        flex-shrink: 0;
        width: 100%;
        max-width: 280px;
      }
      .bio {
        & > *:last-child {
          margin-bottom: 0;
        }
        h4 {
          margin-bottom: 20px;
        }
      }
      .affiliation {
        margin-top: 32px;
      }
    }
    .description {
      text-align: center;
      &.above {
        max-width: 568px;
        margin: 0 auto 60px;
      }
      &.below {
        max-width: 911px;
        margin: 64px auto 0;
      }
    }
  }
}
.family-home .home-row {
  &-1 {
    height: auto;
    margin-top: 13px;
    @include media(">768px") {
      height: 67vw;
    }
    @include media(">=ipadh") {
      height: 54vw;
    }
    @include media(">=mdpi") {
      height: 49vw;
    }
    @include media(">=hidpi") {
      height: 50vw;
    }
    @include media(">=1600px") {
      height: 42vw;
    }
    @include media(">=desktop") {
      height: 39vw;
    }
    .home-row-content {
      display: block !important;
      flex-direction: unset;
      justify-content: unset;
      align-items: unset;
      @include media(">=hidpi") {
        padding-top: 50px;
      }
      @include media(">=desktop") {
        padding-top: 70px;
      }
      .alt-action {
        margin-top: 0;
        margin-bottom: 45px;
        @include media(">638px") {
          text-align: left;
        }
        @include media(">=ipadh") {
          margin-top: 1.25rem;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
    h1 {
      max-width: 280px;
      margin: 0 auto;
      line-height: 0.9 !important;
      span {
        display: block;
        line-height: 1;
        font-size: 85%;
        margin-top: 0.25em;
      }
      @include heading-fs(hero);
      @include media(">638px") {
        max-width: 280px;
        margin: 0;
        text-align: left;
      }

      @include media(">=ipadh") {
        max-width: 740px;
        margin: 0 auto;
        @include heading-fs(hero-plus);
        text-align: center;
      }
      @include media(">=1200px") {
        @include heading-fs(hero-mega);
      }
    }
    h2 {
      margin: 4vw auto 0;
      text-align: center;
      font-weight: $fw-r;
      font-family: $ff-roboto;
      font-style: normal;
      @include front-fs(h3);
      @include color(color, primary);
      @include media(">638px") {
        max-width: 230px;
        margin: 4vw 0 0;
        text-align: left;
      }
      @include media(">=ipadh") {
        max-width: 570px;
        margin: 5vw auto 0;
        text-align: center;
      }
      @include media(">=hidpi") {
        margin: 5vw auto 0;
      }
      @include media(">=desktop") {
        margin: 4vw auto 0;
      }
    }
    button {
      margin: 35px auto 45px;
      @extend .button;
      padding: 25px 40px;
      @include color(background-color, accent-teal);
      @include color(border-color, accent-teal-dark);
      box-shadow: 0px 4px 24px rgba(0, 168, 180, 0.45);
      border-radius: 10px;
      text-transform: uppercase;
      &:hover {
        @include color(background-color, primary);
        @include color(color, white);
      }
      @include media(">638px") {
        margin: 35px 0 25px;
        min-width: 287px;
      }
      @include media(">=ipadh") {
        margin-top: 4vw;
        margin-bottom: 0;
        text-align: center;
        display: inline-block;
      }
      @include media(">=mdpi") {
        margin-top: 4vw;
      }
      @include media(">=hidpi") {
        margin-top: 4vw;
      }
      @include media(">=desktop") {
        margin-top: 4vw;
      }
    }
  }
}
.proband-home .scroll-action .scroll-action-text {
  display: none;
}
.trees {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 589px;
  height: 692px;
  background-image: url("../../images/trees/heart_tree_group_v2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include media("<638px") {
    display: none;
  }
  @include media(">=638px") {
    //768
    // width: 520px;
    // height: 585px;
    width: 430px;
    height: 496px;
    bottom: -60px;
  }
  @include media(">=800px") {
    width: 520px;
    height: 585px;
    bottom: -30px;
  }
  @include media(">=834px") {
    bottom: -12px;
  }
  @include media(">=ipadh") {
    width: 450px;
    height: 575px;
    bottom: 0;
  }
  @include media(">=mdpi") {
    width: 554px;
    height: 675px;
  }
  @include media(">=desktop") {
    width: 620px;
    height: 762px;
  }
  .tree {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &-left {
    display: none;
    left: 0;
    transform: scaleX(-1);
    @include media(">=ipadh") {
      display: block;
    }
  }
  &-right {
    display: none;
    right: 0;
    @include media(">=ipadh") {
      display: block;
    }
    .tree {
      transform: scaleX(-1);
    }
  }
}
.family-home .trees {
  &-right {
    display: none;
    right: 0;
    @include media(">638px") {
      display: block;
    }
  }
}
