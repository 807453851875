@use "../../abstracts/" as *;
.card-login {
  width: 100%;
  max-width: 490px;
  margin: 40px 20px 20px;
  h2 {
    width: 100%;
    text-align: center;
  }
  form {
    width: 100%;
  }
  .button {
    width: 100%;
  }
}
.validation-error {
  @include color(color, secondary-dark);
  @include form-fs(helper);
  ul {
    padding: 0 0 0 15px;
  }
}
