@use "../abstracts/" as *;
@use "../abstracts/media" as *;
.footer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
  padding: 8px $page-padding-mobile;
  @include paragraph-fs(small);
  @include color(background-color, accent-light-bg);
  @include media(">=ipadh") {
    min-height: 73px;
    padding: 16px $page-padding-tablet;
  }
  @include media(">=hidpi") {
    padding: 16px $page-padding-desktop;
  }
}
.footer-copyright {
  .date {
    @include media("<ipadh") {
      display: block;
    }
  }
  .copy {
    @include media("<ipadh") {
      display: block;
    }
  }
}
.footer-help {
  position: absolute;
  top: -25px;
  right: $page-padding-mobile;
  z-index: 50;
  @include media(">=ipadh") {
    right: $page-padding-tablet;
  }
  @include media(">=hidpi") {
    right: $page-padding-desktop;
  }
}
.footer-help-button {
  position: fixed;
  bottom: 41px;
  right: 36px;
  width: 53px;
  height: 0;
  padding-top: 50px;
  overflow: hidden;
  background: url(../../images/help_solid.svg) no-repeat center;
  background-size: contain;
  border: none;
  cursor: pointer;
  transition: transform 0.5s ease;
  &:hover {
    transform: translateY(-3px);
  }
  @include media("<ipadh") {
    bottom: 10px;
    right: 10px;
    width: 35px;
    padding-top: 32px;
  }
}
.footer-help-menu {
  position: fixed;
  bottom: 110px;
  right: 35px;
  width: 140px;
  @include color(background-color, gray-med);
  @include color(color, white);
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  @include media("<ipadh") {
    bottom: 60px;
    right: 0px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 16px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid;
    @include color(border-top-color, gray-med);
  }
  &.active {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  button {
    width: 100%;
    padding: 15px 10px;
    background: transparent;
    border: none;
    border-bottom: 1px solid;
    @include color(border-bottom-color, white);
    @include color(color, white);
    &:hover {
      text-decoration: underline;
      @include color(color, white);
      @include color(background, gray-med);
    }
    &.highlight {
      text-decoration: underline;
    }
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-bottom: none;
      border-radius: 0 0 5px 5px;
    }
  }
}
