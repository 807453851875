@use "../abstracts/" as *;

.flow > * + * {
  margin-top: $flow-space;
}
.flow-small > * + * {
  margin-top: $flow-space-small;
}
.flow-med > * + * {
  margin-top: $flow-space-med;
}
.flow-large > * + * {
  margin-top: $flow-space-large;
}
.m-center {
  margin: 0 auto;
}
//Padding
.p-100 {
  padding: 10px;
}
.p-200 {
  padding: 20px;
}
.p-300 {
  padding: 30px;
}
.p-400 {
  padding: 40px;
}
//Padding Top
.pt-200 {
  padding-top: 10px;
}
.pt-300 {
  padding-top: 20px;
}
.pt-400 {
  padding-top: 35px;
}
//Padding Right
.pr-200 {
  padding-right: 10px;
}
.pr-300 {
  padding-right: 20px;
}
.pr-400 {
  padding-right: 35px;
}
//Padding Bottom
.pb-200 {
  padding-bottom: 10px;
}
.pb-300 {
  padding-bottom: 20px;
}
.pb-400 {
  padding-bottom: 35px;
}
//Padding Left
.pl-200 {
  padding-left: 10px;
}
.pl-300 {
  padding-left: 20px;
}
.pl-400 {
  padding-left: 35px;
}
//Margin Top
.mt-200 {
  margin-top: 10px;
}
.mt-300 {
  margin-top: 20px;
}
.mt-400 {
  margin-top: 35px;
}
.mt-500 {
  margin-top: 45px;
}
.mt-600 {
  margin-top: 55px;
}
//Margin Right
.mr-200 {
  margin-right: 10px;
}
.mr-300 {
  margin-right: 20px;
}
.mr-400 {
  margin-right: 35px;
}
//Margin Bottom
.mb-0 {
  margin-bottom: 0px;
}
.mb-200 {
  margin-bottom: 10px;
}
.mb-300 {
  margin-bottom: 20px;
}
.mb-400 {
  margin-bottom: 35px;
}
.mb-500 {
  margin-bottom: 45px;
}
.mb-600 {
  margin-bottom: 55px;
}
//Margin Left
.ml-200 {
  margin-left: 10px;
}
.ml-300 {
  margin-left: 20px;
}
.ml-400 {
  margin-left: 35px;
}
