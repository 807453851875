@use "../../abstracts/" as *;
@use "../../abstracts/media" as *;
.counselor {
  position: relative;
  padding: 55px 0 0;
  margin: 0 0 35px 0;
  @include media(">=ipadh") {
    padding: 50px 0 0;
    margin: 0 0 45px 0;
  }
  @include media(">=hidpi") {
    padding: 46px 0 0;
  }
  &-avitar {
    position: absolute;
    top: 0;
    left: 45px;
    width: 76px;
    height: 73px;
    background-image: url(../../../images/sonia.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &-indicator {
    position: absolute;
    top: 48px;
    left: 120px;
    width: 73px;
    height: 36px;
    background-image: url(../../../images/speech_indicator.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &-bubble {
    position: relative;
    padding: 9vw 0 50px;
    @include media(">390px") {
      padding: 36px 0 50px;
    }
    @include media(">=ipadh") {
      padding: 52px 0 50px;
    }
    @include media(">=hidpi") {
      padding: 58px 0 50px;
    }
    &-top {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background-image: url(../../../images/speech_bubble_top.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      @include media(">=ipadh") {
        height: 53px;
      }
      @include media(">=hidpi") {
        height: 61px;
      }
    }
    &-middle {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      padding: 0 20px;
      font-family: $ff-sspro;
      font-weight: $fw-m;
      @include serif-fs(counselor);
      @include color(color, primary);
      @include color(background-color, bubble);
      > div {
        max-width: 300px;
        //animation: fadeIn 1.5s;
        @include media(">=ipadh") {
          max-width: 400px;
        }
        @include media(">=hidpi") {
          max-width: 456px;
        }
      }
      p {
        margin-bottom: 0.5em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-bottom {
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 58px;
      background-image: url(../../../images/speech_bubble_bottom.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      filter: drop-shadow(0 8px 8px rgb(0 0 0 / 0.08));
    }
  }
}
.counselor-more {
  position: relative;
  padding: 0;
  margin: -35px 0 35px 0;
  @include media(">=ipadh") {
    padding: 0;
    margin: -45px 0 45px 0;
  }
}
