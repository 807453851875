@use "../abstracts/" as *;
@use "../abstracts/media" as *;
.help-request {
  .counselor {
    &-bubble {
      position: relative;
      padding: 8vw 0 50px;
      @include media(">390px") {
        padding: 50px 0 50px;
      }
      @include media(">=ipadh") {
        padding: 52px 0 50px;
      }
      @include media(">=hidpi") {
        padding: 50px 0 50px;
      }
    }
  }
}
