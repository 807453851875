@use "../../abstracts/" as *;
@use "../../abstracts/media" as *;
.lang-desktop {
  position: relative;
  width: 140px;
}
.lang-current {
  &::after {
    font-family: "Material Icons";
    content: "expand_more";
    position: relative;
    top: 2px;
  }
  &:hover {
    text-decoration: none !important;
  }
}
.lang-menu {
  position: absolute;
  z-index: 600;
  top: 40px;
  right: -52px;
  width: 160px;
  @include color(background, primary);
  @include color(color, text);
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  @include media(">=hidpi") {
    right: -8px;
  }
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid;
    @include color(border-bottom-color, primary);
  }
  &.active {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 15px 10px;
    background: transparent;
    border: none;
    border-bottom: 1px solid;
    text-decoration: none;
    text-align: center;
    font-family: $ff-bilo;
    font-weight: $fw-r;
    @include color(border-bottom-color, primary-med);
    @include color(color, white);
    &:hover {
      @include color(color, white);
      @include color(background, primary);
    }
    &.highlight {
      @include color(background, primary-med);
    }
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-bottom: none;
      border-radius: 0 0 5px 5px;
    }
  }
}
.lang-option {
  position: relative;
  &.active {
    text-decoration: underline;
  }
}
