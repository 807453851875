.img-inline {
  display: flex;
  justify-content: center;
  & > * + * {
    margin-inline-start: 2em;
  }
}
.drawing-small {
  width: 105px;
  height: auto;
}
.drawing {
  width: 135px;
  height: auto;
}
.drawing-160 {
  width: 160px;
  height: auto;
}
.drawing-med {
  width: 204px;
  height: auto;
}
.drawing-large {
  width: 288px;
  height: auto;
}
.drawing-center {
  margin: 0 auto;
}
