@use "../../abstracts/" as *;
@use "../../abstracts/media" as *;
.block-back-to-top {
  display: flex;
  justify-content: center;
  padding: 28px 0;
  @include color(background-color, accent-desat-teal-lt);
  button {
    position: relative;
    padding: 0 34px 0 0;
    background: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    @include paragraph-fs(small);
    @include color(color, primary);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 25px;
      background: url(../../../images/back_to_top.svg) no-repeat center;
      transition: all 0.5s ease-in;
    }
    &:hover {
      @include color(color, primary-med);
      &::after {
        transform: translateY(-3px);
      }
    }
  }
}
