@use "../abstracts/" as *;
@use "../abstracts/media" as *;
.content button:not(.icon),
.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 125px;
  padding: 12px 18px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  letter-spacing: 0.01em;
  text-decoration: none;
  font-weight: $fw-m;
  font-family: $ff-roboto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  // transition: all 0.5s ease;
  @include color(background-color, primary);
  @include color(border-color, primary);
  @include color(color, white);
  @include button-fs(default);
  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    @include color(background-color, white);
    @include color(border-color, gray-med-light);
    @include color(color, gray-med-light);
  }
  &:hover {
    @include color(background-color, white);
    @include color(color, primary);
    &:disabled {
      @include color(color, gray-med-light);
    }
  }
  &.small {
    padding: 12px 18px;
    letter-spacing: 0.03em;
    font-weight: $fw-r;
    font-family: $ff-roboto;
    @include color(background-color, white);
    @include color(border-color, primary);
    @include color(color, primary);
    @include button-fs(small);
    &:hover {
      @include color(background-color, primary);
      @include color(color, white);
    }
  }
  &.neutral {
    font-weight: $fw-sb;
    @include color(background-color, white);
    @include color(color, primary);
    &:hover {
      @include color(background-color, primary);
      @include color(color, white);
    }
  }
  &.next {
    padding-right: 10px;
    &::after {
      font-family: "Material Icons";
      content: "chevron_right";
      margin-left: 10px;
    }
  }
  &.previous {
    padding-left: 10px;
    &::before {
      font-family: "Material Icons";
      content: "chevron_right";
      margin-right: 10px;
      transform: rotate(180deg);
    }
  }
  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
  &.text {
    background: none;
    padding: 0;
    margin: 0;
    display: inline;
    box-shadow: none;
    border: none;
    @include color(color, primary);
    text-decoration: underline;
  }
  &.button-relative-selector {
    flex-direction: column;
    align-items: flex-start;
    .relationship {
      @include paragraph-fs(small);
      font-weight: $fw-r;
    }
  }
  &.button-steps-selector {
    flex-direction: column;
    align-items: flex-start;
    .name {
      @include paragraph-fs(small);
      font-weight: $fw-r;
      text-transform: uppercase;
    }
    .description {
      @include heading-fs(h5);
      text-align: left;
    }
    &.done {
      @include color(background-color, gray-light);
      @include color(border-color, gray-med-dark);
      @include color(color, gray-med-dark);
      &:hover {
        @include color(background-color, white);
      }
    }
  }
}
a,
a:visited {
  &.button {
    @include color(color, white);
    &:hover {
      @include color(color, primary);
    }
  }
  &.button.small {
    @include color(color, primary);
    &:hover {
      @include color(color, white);
    }
  }
  &.button.neutral {
    @include color(color, primary);
    &:hover {
      @include color(color, white);
    }
  }
}
.button-link {
  cursor: pointer;
  font-weight: $fw-r;
  font-family: $ff-roboto;
  background: transparent;
  border: none;
  @include color(color, primary);
  @include paragraph-fs(small);
  &:hover {
    @include color(color, accent-teal-dark);
  }
}
.buttons-inline {
  display: flex;
  justify-content: center;
  & > * + * {
    margin-inline-start: 2em;
  }
}
.buttons-stack {
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 1rem;
  }
  @include media(">=ipadh") {
    flex-direction: row;
    justify-content: center;
    & > * + * {
      margin-top: 0;
      margin-left: 1rem;
    }
  }
}
.buttons-column {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  & > * + * {
    margin-top: 1rem;
  }
}
.neutral {
  button:not(.icon) {
    font-weight: $fw-sb;
    @include color(background-color, white);
    @include color(color, primary);
    &:hover {
      @include color(background-color, primary);
      @include color(color, white);
    }
  }
}
