@use "../abstracts/" as *;
@use "../abstracts/media" as *;

.header {
  position: absolute;
  z-index: 498;
  width: 100%;
  height: $header-mobile;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px $page-padding-mobile;
  @include color(background-color, page-background);
  @include media(">=ipadh") {
    height: $header-tablet;
    padding: 16px $page-padding-tablet;
  }
  @include media(">=hidpi") {
    height: $header-desktop;
    padding: 16px $page-padding-desktop;
    align-items: flex-start;
  }
  &.can-animate {
    transition: transform 0.3s ease, visibility 0s 0.3s linear;
  }
  &.is-fixed {
    position: fixed;
    z-index: 500;
    transform: translate3d(0, -100%, 0);
    border-bottom: solid 1px;
    @include color(border-color, gray-light);
    @include media(">=hidpi") {
      height: 95px;
    }
  }
  &.scroll-up {
    transform: translate3d(0, 0, 0);
  }
}
.header-mobile-logo {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  @include media(">=ipadh") {
    flex-grow: 0;
  }
  @include media(">=hidpi") {
    position: absolute;
    top: 59px;
    left: calc(50% - 87.5px);
    opacity: 0;
    visibility: hidden;
  }
  img {
    width: 100%;
    height: auto;
    max-width: 60px;
    margin-left: 78px;
    @include media(">=ipadh") {
      max-width: 100px;
      margin-left: 0;
    }
  }
}
.is-fixed .header-mobile-logo {
  opacity: 1;
  visibility: visible;
  top: 10px;
}
.header-mobile-menu {
  flex: 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media(">=ipadh") {
    display: none;
  }
}
.header-mobile-button {
  width: 48px;
  height: 0;
  padding-top: 48px;
  overflow: hidden;
  background-image: url("../../images/hamburger_menu.svg");
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.header-mobile-dropdown {
  position: absolute;
  z-index: 600;
  top: 58px;
  right: 15px;
  width: 160px;
  @include color(background, primary);
  @include color(color, text);
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 24px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid;
    @include color(border-bottom-color, primary);
  }
  &.active {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 15px 10px;
    background: transparent;
    border: none;
    border-bottom: 1px solid;
    text-decoration: none;
    text-align: center;
    font-family: $ff-bilo;
    font-weight: $fw-r;
    @include color(border-bottom-color, primary-med);
    @include color(color, white);
    &:hover {
      text-decoration: none;
      @include color(color, white);
      @include color(background, primary);
    }
    &.highlight {
      @include color(background, primary-med);
    }
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-bottom: none;
      border-radius: 0 0 5px 5px;
    }
  }
}
.header-desktop-logo {
  display: none;
  @include media(">=hidpi") {
    display: block;
    position: absolute;
    top: 59px;
    left: calc(50% - 87.5px);
    opacity: 1;
    visibility: visible;
  }
}
.is-fixed .header-desktop-logo {
  opacity: 0;
  visibility: hidden;
}
.header-desktop-menu {
  display: none;
  @include media(">=ipadh") {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
  @include media(">=hidpi") {
    flex-grow: 0;
    margin-top: 21px;
  }
  a,
  a:visited {
    display: block;
    width: 160px;
    text-decoration: none;
    text-align: center;
    font-family: $ff-roboto;
    font-weight: $fw-r;
    @include paragraph-fs(small);
    @include color(color, primary);
    &:hover {
      @include color(color, primary-med);
      text-decoration: underline;
    }
    @include media(">=hidpi") {
      text-align: left;
    }
  }
}
.header-sign-in {
  display: none;
  @include media(">=ipadh") {
    display: flex;
  }
  @include media(">=hidpi") {
    margin-top: 19px;
  }
  a,
  a:visited {
    text-decoration: none;
    font-family: $ff-roboto;
    font-weight: $fw-r;
    @include paragraph-fs(small);
    @include color(color, primary);
    &:hover {
      @include color(color, primary-med);
      text-decoration: underline;
    }
  }
}
