@use "sass:list";
@use "./fonts" as *;
@use "./colors" as *;

@mixin size-generator($values) {
  $multiplier: 1 / $base-font-size;
  $size: list.nth($values, 1);
  $lh: list.nth($values, 2);
  font-size: $size + px;
  font-size: $size * $multiplier + rem;
  line-height: $lh + px;
  line-height: $lh * $multiplier + rem;
}

@mixin front-fs($name) {
  $values: map-get($front-sizes, $name);
  @include size-generator($values);
}

@mixin heading-fs($name) {
  $values: map-get($heading-sizes, $name);
  @include size-generator($values);
}

@mixin paragraph-fs($name) {
  $values: map-get($paragraph-sizes, $name);
  @include size-generator($values);
}

@mixin button-fs($name) {
  $values: map-get($button-sizes, $name);
  @include size-generator($values);
}

@mixin form-fs($name) {
  $values: map-get($form-sizes, $name);
  @include size-generator($values);
}

@mixin serif-fs($name) {
  $values: map-get($serif-sizes, $name);
  @include size-generator($values);
}

@mixin color($property, $value) {
  #{$property}: var(--#{$value});
}

@mixin padding($direction, $name) {
  #{$direction}: map.get($page-padding, $name);
}
