@use "../../abstracts/" as *;
.tracker-steps-label {
  margin-left: 34px;
  text-transform: uppercase;
  @include paragraph-fs(small);
}
.tracker-steps-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .check-box {
    flex-shrink: 0;
    position: relative;
    width: 26px;
    height: 25px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-image: url(../../../images/open_circle@2x.png);
    background-size: cover;

    &.active {
      background-image: url(../../../images/check.png);
    }
  }
  .title {
    h2 {
      margin-bottom: 0;
    }
  }
}
.tracker-steps-choices-add-relative {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.tracker-steps-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * + * {
    margin-top: 1rem;
  }
}
