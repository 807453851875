@use "../../abstracts/" as *;
.celebrate-animation {
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  @include color(background-color, primary-light);
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.celebrate-text {
  margin-top: 1em;
  font-style: italic;
  font-family: $ff-sspro;
  @include serif-fs(blockquote);
}
