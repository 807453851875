@use "../../abstracts/" as *;
.progress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  .title {
    text-align: center;
    @include paragraph-fs(small);
  }
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:not(.active) {
      .title,
      .sub-title {
        @include color(color, gray-med);
      }
    }
  }
  .pager {
    display: flex;
    justify-content: center;
  }
  .step {
    width: 23px;
    height: 0;
    padding-top: 24px;
    overflow: hidden;
    background-image: url(../../../images/steps/dot.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    &.active {
      background-image: url(../../../images/steps/dot_active.png);
      @include color(background-color, primary);
    }
    &.complete {
      @include color(background-color, primary);
    }
  }
}
