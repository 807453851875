@use "../abstracts/" as *;
section.page-counter {
  text-align: center;
}
section.doc {
  height: 500px;
  padding: 30px;
  overflow-y: scroll;
  border-width: 1px;
  border-style: solid;
  @include color(border-color, gray-med);
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.hr {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  @include color(border-color, gray-light);
}
