@use "../abstracts/" as *;
@use "../abstracts/media" as *;

:root {
  @each $property, $value in $colors {
    --#{$property}: #{$value};
  }
}

// * {
//   background: rgb(0 100 0 / 0.05) !important;
// }

body {
  display: flex;
  flex-direction: column;
  @include color(background-color, page-background);
  &.modal-active {
    overflow: hidden;
  }
}
.header {
  flex-shrink: 0;
  flex-grow: 0;
}
.main {
  flex-grow: 1;
  margin-top: $header-mobile;
  @include media(">=ipadh") {
    margin-top: $header-tablet;
  }
  @include media(">=hidpi") {
    margin-top: $header-desktop;
  }
}
.header {
  flex-shrink: 0;
  flex-grow: 0;
}
