@use "../abstracts/" as *;
@use "../abstracts/media" as *;
.form {
  margin-inline: auto;
  width: min(90%, $column-tablet);
  @include media(">=390px") {
    width: 100%;
  }
}
.note {
  font-weight: $fw-r;
  @include paragraph-fs(small);
  @include color(color, gray-dark);
}
.correct {
  position: relative;
  @include color(background-color, accent-desat-teal-lt);
  &::before {
    content: "";
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
    height: 9px;
    @include color(background-color, accent-desat-teal-lt);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 7px;
    @include color(background-color, accent-desat-teal-lt);
  }
}
.selected {
  position: relative;
  @include color(background-color, secondary-light);
  &::before {
    content: "";
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
    height: 9px;
    @include color(background-color, secondary-light);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 7px;
    @include color(background-color, secondary-light);
  }
}
.iti--laravel-tel-input:disabled {
  @include color(color, black);
}
