.fancy-checks {
  li {
    position: relative;
    padding-left: 45px;
    list-style: none;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - 16px);
      left: 0;
      width: 32px;
      height: 32px;
      background-image: url(../../images/check.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
