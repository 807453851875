@use "../abstracts/" as *;
@use "../abstracts/media" as *;
.card-base {
  position: relative;
  display: flex;
  border-radius: 8px;
  border: solid 1px;
  @include color(border-color, accent-teal-med);
  @include color(background-color, white);
}
.card {
  @extend .card-base;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  &.shadow {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }
  &.center {
    align-items: center;
  }
  &-more-info {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    width: 24px;
    height: 0;
    padding-top: 24px;
    overflow: hidden;
    border: none;
    background: url("../../images/info.png") no-repeat center transparent;
    background-size: contain;
    cursor: pointer;
  }
}
.card-row {
  @extend .card-base;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  min-height: 160px;
  .col-1 {
    flex-shrink: 0;
    padding-right: 20px;
  }
  .col-2 {
    & > *:last-child {
      margin-bottom: 0;
    }
  }
  &.flip {
    flex-direction: row-reverse;
    .col-1 {
      padding-right: 0;
    }
    .col-2 {
      padding-right: 20px;
    }
  }
}
.card-link {
  text-decoration: none;
}
