$base-font-size: 16; //in pixels, used to calc rems

$ff-bilo: bilo, sans-serif;
$ff-roboto: roboto, sans-serif;
$ff-sspro: source-serif-pro, serif;

$fw-r: 400;
$fw-m: 500;
$fw-sb: 600;
$fw-b: 700;

$front-sizes: (
  h2: 48 52,
  h2-sub: 44 48,
  h3: 28 31,
);

$heading-sizes: (
  hero-sub-mini: 28 38,
  hero-sub: 32 40,
  hero-sub-plus: 42 44,
  hero: 52 56,
  hero-plus: 64 68,
  hero-mega: 78 82,
  h1: 64 70,
  h1-plus: 64 70,
  h2: 32 35,
  h2-plus: 32 35,
  h3: 28 30,
  h3-plus: 28 30,
  h4: 24 26,
  h5: 20 22,
);

$paragraph-sizes: (
  regular: 18 24,
  small: 16 22,
);

$button-sizes: (
  default: 18 24,
  small: 16 18,
);

$form-sizes: (
  label: 18 24,
  field: 18 24,
  helper: 16 20,
);

$serif-sizes: (
  counselor: 32 38,
  intro: 28 36,
  intro-plus: 32 42,
  blockquote: 20 30,
);
